import { odienceColors } from "@/styles/global.styles";
import { CategoryKey } from "@/types/odience";
import { categories } from "@/utils/constants/odience";
import { isMobile } from "@/utils/helpers/Utils";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { css } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import {
  getCategoryImage,
  getUniqueCategories,
} from "../helpers/EventListUtils";
import WebEventsOnlyInput from "./WebEventsOnlyInput";

type EventCategoriesProps = {
  events: OdienceEvent[] | undefined;
  onSelectedCategory: (selectedCategory: CategoryKey) => void;
  selectedCategory: CategoryKey;
};

const EventCategories = ({
  events,
  selectedCategory,
  onSelectedCategory,
}: EventCategoriesProps) => {
  const selectedCategoryDisplay = categories[selectedCategory];

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null!);

  const [selectedCategoryImg, setSelectedImage] = useState(
    getCategoryImage(selectedCategory)
  );

  const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);

  useEffect(() => {
    if (events) {
      const uniqueCategories = getUniqueCategories(events);
      setUniqueCategories(uniqueCategories);
    }
  }, [events]);

  const handleCategoryChange = (category: CategoryKey) => {
    onSelectedCategory(category);
    setSelectedImage(getCategoryImage(category));
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useOnClickOutside(dropdownRef, () => setDropdownOpen(false));

  const renderCategoryOptions = () => {
    return uniqueCategories.map((category) => (
      <li
        key={category}
        css={{
          ":hover": {
            backgroundColor: odienceColors.midnightBlue,
          },
        }}
      >
        <button
          type="button"
          onClick={() => handleCategoryChange(category as CategoryKey)}
          css={buttonStyle}
        >
          <img
            src={getCategoryImage(category as CategoryKey)}
            alt={categories[category as CategoryKey]}
            css={imageStyle}
          />
          <span>{categories[category as CategoryKey]}</span>
        </button>
      </li>
    ));
  };

  return (
    <div css={containerStyle}>
      <div css={dropdownContainerStyle} ref={dropdownRef}>
        <div css={buttonContainerStyle}>
          <button
            type="button"
            onClick={toggleDropdown}
            css={dropdownButtonStyle}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <img src={selectedCategoryImg} css={iconStyle} />
              <span>{selectedCategoryDisplay}</span>
            </div>
            <ExpandMoreIcon
              css={[
                expandIconStyle,
                {
                  transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
                },
              ]}
            />
          </button>
          {isDropdownOpen && (
            <ul css={dropdownListStyle}>{renderCategoryOptions()}</ul>
          )}
        </div>
      </div>
      {!isMobile() && <WebEventsOnlyInput />}
    </div>
  );
};

export default EventCategories;

const containerStyle = css({
  display: "flex",
  gap: "2vw",
  justifyContent: isMobile() ? "center" : undefined,
  position: "relative",
  marginBottom: "1em",
});

const dropdownContainerStyle = css({
  color: odienceColors.pureWhite,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  backgroundColor: odienceColors.onyxGray,
  position: "relative",
  width: "21em",
  borderRadius: "10px",
  fontWeight: "bold",
});

const buttonContainerStyle = css({
  width: "100%",
  padding: "0.25em",
});

const dropdownButtonStyle = css({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  width: "100%",
  padding: "0em 1em",
  textAlign: "start",
  gap: "1.5em",
  minWidth: "15em",
  position: "relative",
  zIndex: 2,
  height: "4em",
  justifyContent: "space-between",
});

const iconStyle = css({
  height: "1.25em",
  width: "1.25em",
});

const expandIconStyle = css({
  transition: "transform 0.3s ease",
  fontWeight: 700,
  height: "1.25em",
  width: "1.25em",
});

const dropdownListStyle = css({
  listStyle: "none",
  padding: 0,
  margin: 0,
  width: "100%",
  position: "absolute",
  backgroundColor: odienceColors.onyxGray,
  top: "100%",
  left: 0,
  zIndex: 2,
});

const buttonStyle = css({
  display: "flex",
  alignItems: "center",
  border: "none",
  background: "none",
  cursor: "pointer",
  width: "100%",
  padding: "0.5em 1em",
  textAlign: "start",
  gap: "1.5em",
});

const imageStyle = css({
  height: "1.2em",
  width: "1.2em",
  marginRight: "1em",
});
