import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import AddEmailModal from "@/pages/profile/components/AddEmailModal";
import { LayoutContext } from "@/pages/profile/utils/LayoutContext";
import { useProfileModal } from "@/pages/profile/utils/ProfileModalContext";
import { checkIfUserHasInvitedEmail } from "@/pages/profile/utils/ProfileScreenUtils";
import { paths } from "@/routerPaths";
import { odienceColors } from "@/styles/global.styles";
import { CategoryKey } from "@/types/odience";
import { isMobile } from "@/utils/helpers/Utils";
import { atoms } from "@/utils/helpers/atoms";
import { ls } from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { ss } from "@/utils/helpers/sessionStorage";
import useProvisioningModal from "@/utils/hooks/useProvisioningModal";
import useParentIframeUrl from "@/utils/hooks/useUpdateIframeParent";
import { css } from "@emotion/react";
import { useAtom, useAtomValue } from "jotai";
import { use, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_AVATAR } from "../../../../utils/hooks/useDirectorAuthentication";
import { useOdienceEvents } from "../../../../utils/hooks/useOdienceEvents";
import GetAppModal from "../../components/GetAppModal";
import LoadingAnimation from "../../components/LoadingAnimation";
import OdiencePreviewMobileHeader from "../../components/OdiencePreviewMobileHeader";
import EventCard from "./components/EventCard";
import EventCategories from "./components/EventCategories";
import EventListHeader from "./components/EventListHeader";
import { navigateToEventDetails } from "./helpers/EventListUtils";

const EventList = () => {
  const isChecked = useAtomValue(atoms.odience.webOnlyEvents);
  const [getAppModalOpen, setGetAppModalOpen] = useState(false);
  const { openModal, userAvatar, updateUserAvatar } = useProfileModal();

  const [odienceAvatar, setOdienceAvatar] = useState<string>(
    userAvatar || DEFAULT_AVATAR
  );
  const [selectedCategory, setSelectedCategory] = useState(() =>
    ls.getOdienceSelectedCategory()
  );
  const [showPopup, setShowPopup] = useAtom(atoms.odience.privateEventPopup);

  const toggleGetAppModal = () => setGetAppModalOpen(!getAppModalOpen);
  const handleOdienceAvatarError = () => setOdienceAvatar(DEFAULT_AVATAR);

  const navigate = useNavigate();
  const invitedEventId = ss.getInvitedEventId();
  const inviteId = ss.getInvite();

  const { objEvents, eventsPending, embeddedMode } = useOdienceEvents({
    updateUserAvatar,
  });
  const odienceUser = ls.getOdienceUser();
  const { modal: provisioningModal, open: openProvisioning } =
    useProvisioningModal({
      onSuccessPathRedirect: paths.odience,
      fromOdience: true,
    });

  const [showAddEmailPopup, setShowAddEmailPopup] = useState(false);
  const { hasNavBar } = use(LayoutContext);
  const updateIframeUrl = useParentIframeUrl({
    initialUrl: window.location.href,
    embeddedMode,
    // targetOrigin: "https://www.odience.com",
  });

  const userNotProvisioned = !isProvisioned();
  const showLoginPopup =
    (showPopup && embeddedMode && userNotProvisioned) ||
    ((ss.getInvite() || ss.getEmail()) && userNotProvisioned);

  const toggleEmailPopup = () => {
    setShowAddEmailPopup(!showAddEmailPopup);
  };

  useEffect(() => {
    if (inviteId && isProvisioned() && odienceUser) {
      checkIfUserHasInvitedEmail(inviteId);
    }
  }, [inviteId, odienceUser]);

  useEffect(() => {
    if (isProvisioned() && ss.getInvite()) {
      setShowAddEmailPopup(true);
    }
  }, []);

  useEffect(() => {
    updateIframeUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (!invitedEventId || !objEvents) {
      return;
    }
    const matchingEvent = objEvents.find(
      (event) => event.id === invitedEventId
    );

    if (matchingEvent) {
      const organizationId = matchingEvent.organization_id;
      void navigate(
        navigateToEventDetails(
          paths.previewOdienceDetails,
          organizationId,
          invitedEventId
        )
      );
      ss.removeInvitedEventId();
    }
  }, [objEvents, invitedEventId]);

  useEffect(() => {
    setOdienceAvatar(userAvatar);
  }, [userAvatar]);

  const odienceDetail = `
    Experience immersive 360 shopping, concerts and more. Interact with the event host,
    video call a friend, explore the space, or video call a friend and share the experience.
    Available for mobile devices, tablets, desktops, and VR glasses.
  `;

  const eventList = objEvents
    ?.filter((event) => {
      if ((embeddedMode || isChecked) && event.web_allowed === 0) return false;
      if (event.label === "ended") return false;
      if (selectedCategory !== "all") {
        if (selectedCategory === "invited") return event.invitation_accepted;
        return event.category === selectedCategory;
      }
      return true;
    })
    .sort((a, b) => {
      // show featured events first
      if (a.featured && !b.featured) return -1;
      if (!a.featured && b.featured) return 1;
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    })
    .map((event) => <EventCard key={event.id} event={event} />);

  // Avoid loading if cache already present
  if (eventsPending && !objEvents) {
    return (
      <div css={{ width: "100vw", height: "100vh" }}>
        <LoadingAnimation title={"LOADING EVENTS"} />
      </div>
    );
  }

  const handleOnSelectedCategory = (selectedCategory: CategoryKey) => {
    setSelectedCategory(selectedCategory);
    ls.setOdienceSelectedCategory(selectedCategory);
  };

  return (
    <>
      {isMobile() && (
        <OdiencePreviewMobileHeader
          page={"eventList"}
          isMainOdiencePage={true}
        />
      )}
      <div
        css={[
          containerStyle,
          {
            height: hasNavBar ? "90vh" : "100vh",
          },
        ]}
      >
        {!isMobile() && (
          // TODO - fix organizationId that is lost after signing in:
          // - if org id and embedded, always show the organization name
          // - otherwise show ODIENCE
          <EventListHeader
            // organizationTitle={
            //   embeddedMode && organizationId ? objEvents?.[0]?.organization : ""
            // }
            handleGetAppModal={toggleGetAppModal}
            odienceAvatar={odienceAvatar}
            handleOdienceAvatarError={handleOdienceAvatarError}
            handleOpenEditProfile={openModal}
            onShowProvisioning={openProvisioning}
          />
        )}
        {!embeddedMode && <div css={headerStyle}>{odienceDetail}</div>}
        {!embeddedMode && objEvents && (
          <EventCategories
            events={objEvents}
            onSelectedCategory={handleOnSelectedCategory}
            selectedCategory={selectedCategory}
          />
        )}
        {showLoginPopup ? (
          <div css={eventListStyle}>
            <ConfirmationPopup
              title="Event Notification"
              confirmationMessage="A log in is required to access this event"
              handleAction={() => {
                setShowPopup(false);
                openProvisioning();
              }}
              primaryButtonText="Log in"
              togglePopup={() => setShowPopup(false)}
              closeButtonActive={false}
              secondaryButtonActive={false}
            />
          </div>
        ) : (eventList?.length || 0) > 0 ? (
          <div css={eventListStyle}>{eventList}</div>
        ) : userNotProvisioned ? (
          <div css={noEventsAvailableStyle}>
            Log in to view the full list of events
          </div>
        ) : (
          <div css={noEventsAvailableStyle}>No Events Available</div>
        )}
        {getAppModalOpen && (
          <GetAppModal
            show={getAppModalOpen}
            onCloseModal={toggleGetAppModal}
            objEvent={null}
          />
        )}
        {showAddEmailPopup && <AddEmailModal togglePopup={toggleEmailPopup} />}
      </div>

      {provisioningModal}
    </>
  );
};

export default EventList;

const containerStyle = css({
  padding: isMobile() ? "5em 0em" : "3em 2em",
  color: odienceColors.pureWhite,
  backgroundColor: odienceColors.obsidianGray,
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile() ? "center" : "unset",
  overflowY: "auto",
  fontFamily: "Figtree, sans-serif",
  borderRadius: "16px",
  width: "100%",
  "::-webkit-scrollbar": {
    width: "6px",
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: odienceColors.onyxGray,
    borderRadius: "6px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: odienceColors.darkGray,
    borderRadius: "6px",
  },
});

const headerStyle = css({
  color: odienceColors.pureWhite,
  marginTop: "2.75em",
  opacity: "0.5",
  marginBottom: "3rem",
  width: isMobile() ? "90%" : "80%",
  display: "flex",
  justifyContent: "center",
  fontSize: isMobile() ? "0.9rem" : "1.125rem",
});

const eventListStyle = css({
  display: "flex",
  gap: "1.5em",
  flexWrap: "wrap",
  marginTop: "1.5em",
  width: "100%",
  justifyContent: isMobile() ? "center" : undefined,
});

const noEventsAvailableStyle = css({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  fontSize: "1.75rem",
  paddingTop: "2em",
});
