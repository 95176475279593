import EmailIcon from "@/assets/profileScreen/EmailLinkSent.svg";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import PopupOverlay from "@/components/shared/PopupOverlay";
import { colors } from "@/styles/global.styles";
import { ls } from "@/utils/helpers/localstorage";
import { ss } from "@/utils/helpers/sessionStorage";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { MainPopupOverlayCSS } from "../../conversationsScreen/ConversationScreen.style";
import {
  checkIfFieldInUse,
  sendEmailVerification,
} from "../utils/ProfileScreenUtils";
import {
  buttonRow,
  errorMessage,
  profileButton,
  profileHeader,
  profileInput,
  subHeaderRow,
} from "../utils/ProfileStyles";
type EmailProps = {
  togglePopup: () => void;
};

const AddEmailModal = ({ togglePopup }: EmailProps) => {
  const [current, setCurrent] = useState(0);
  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [emailError, setEmailError] = useState<string | false>(false);
  const [eventId, setEventId] = useState("");
  const [closePopupWarning, setClosePopupWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const emailInvite = ss.getEmail();
  const invitedEventId = ss.getInvitedEventId();
  const authenticatedUser = (() => {
    try {
      const rawOdienceUser = ls.getOdienceUser();
      return rawOdienceUser ? JSON.parse(rawOdienceUser) : null;
    } catch {
      return null;
    }
  })();

  useEffect(() => {
    if (emailInvite && emailInvite !== "1") {
      setEmail(emailInvite);
    }
    if (invitedEventId) {
      setEventId(invitedEventId);
    }
  }, [emailInvite, invitedEventId]);

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateEmail = (value: string) => {
    if (!emailRegex.test(value)) {
      return "Invalid email format";
    }
    return false;
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEmail = e.target.value;
    setEmail(updatedEmail);
    const error = validateEmail(updatedEmail);
    setEmailError(error);
  };

  const handleEmailInputBlur = () => {
    setEmailTouched(true);
    const error = validateEmail(email);
    setEmailError(error);
  };

  const handleNext = async () => {
    if (authenticatedUser && email.length > 0) {
      setIsLoading(true);
      const isEmailInUse = await checkIfFieldInUse(
        authenticatedUser.group_id,
        "emailExists",
        email
      );
      setIsLoading(false);

      if (isEmailInUse) {
        setEmailError("This email is already in use");
        return;
      }
    }

    setEmailError(false);
    setCurrent((prev) => prev + 1);
  };

  const handleDismissEmailPopup = () => {
    if (current === 0) {
      setClosePopupWarning(true);
    } else {
      togglePopup();
    }
  };

  const steps = [
    {
      title: "AddEmail",
      content: (
        <AddEmailStep1
          email={email}
          emailTouched={emailTouched}
          emailError={emailError}
          handleEmailInputChange={handleEmailInputChange}
          handleEmailInputBlur={handleEmailInputBlur}
        />
      ),
    },
    {
      title: "Verification Sent",
      content: <VerificationSentStep email={email} eventId={eventId} />,
    },
  ];

  return (
    <>
      <PopupOverlay>
        <div css={MainPopupOverlayCSS}>
          {current === 0 && (
            <div css={profileHeader}>
              <h3 css={{ fontWeight: "bold", fontSize: "1.25rem" }}>
                This is a private event
              </h3>
              <IconButton
                onClick={handleDismissEmailPopup}
                css={{ color: colors.primaryTextColor }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}

          {steps[current].content}

          <div css={[buttonRow, { justifyContent: "flex-end", width: "100%" }]}>
            <button
              type="button"
              onClick={handleDismissEmailPopup}
              css={profileButton}
            >
              {current === 0 ? "Dismiss" : "Close"}
            </button>

            {current === 0 && (
              <button
                type="button"
                disabled={!email || !!emailError || isLoading}
                onClick={async () => {
                  await sendEmailVerification(eventId, email);
                  await handleNext();
                }}
                css={[
                  profileButton,
                  { backgroundColor: colors.primaryAccentColor },
                ]}
              >
                {isLoading ? "Sending..." : "Send"}
              </button>
            )}
          </div>
        </div>
      </PopupOverlay>

      {closePopupWarning && (
        <ConfirmationPopup
          title="Confirm Dismiss AddEmail"
          confirmationMessage="Are you sure you want to dismiss this action? In order to continue adding an email, you will need to click on the invite link in the email you received again."
          handleAction={() => {
            setClosePopupWarning(false);
            togglePopup();
          }}
          primaryButtonText="Dismiss"
          togglePopup={() => setClosePopupWarning(false)}
          closeButtonActive={true}
          secondaryButtonActive={true}
        />
      )}
    </>
  );
};

export default AddEmailModal;

const AddEmailStep1 = ({
  email,
  emailTouched,
  emailError,
  handleEmailInputChange,
  handleEmailInputBlur,
}) => {
  return (
    <>
      <div css={{ color: colors.secondaryTextColor, fontSize: "0.875rem" }}>
        We need to send you a verification link. Please enter the email address
        you were invited with.
      </div>
      <div css={subHeaderRow}>
        {!email && emailTouched && (
          <span css={errorMessage}>
            Please enter an email to see the full list of events
          </span>
        )}
        {emailError && <span css={errorMessage}>{emailError}</span>}
      </div>
      <div css={profileInput}>
        <input
          type="text"
          placeholder="Email"
          value={email || ""}
          onChange={handleEmailInputChange}
          onBlur={handleEmailInputBlur}
        />
      </div>
    </>
  );
};

const VerificationSentStep = ({ email, eventId }) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "2vh",
        alignItems: "center",
        textAlign: "start",
      }}
    >
      <img
        src={EmailIcon}
        alt="Email Sent"
        css={{
          height: "4.5em",
          width: "4.5em",
          maxHeight: "7vh",
          maxWidth: "7vw",
        }}
      />
      <div
        css={{
          color: colors.primaryTextColor,
          textAlign: "start",
          width: "100%",
          fontSize: "0.95rem",
        }}
      >
        A verification link was just sent to {email}. Open the email and click
        the link to continue.
      </div>
      <div
        css={{
          color: colors.secondaryTextColor,
          padding: "2vh 0 1vh 0",
          width: "100%",
          textAlign: "center",
          fontSize: "0.8rem",
        }}
      >
        Didn't receive it? Check your junk mail folder or{" "}
        <span
          css={{
            color: colors.primaryAccentColor,
            cursor: "pointer",
            ":hover": { textDecoration: "underline" },
          }}
          onClick={() => sendEmailVerification(eventId, email)}
        >
          resend the link
        </span>
      </div>
    </div>
  );
};
